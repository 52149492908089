define("discourse/plugins/discourse-legal-compliance/discourse/initializers/init-legal-compliance", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-legal-compliance/discourse/components/modal/delete-uploads-modal"], function (_exports, _pluginApi, _deleteUploadsModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "legal-compliance",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.2.0", api => {
        const currentUser = api.getCurrentUser();
        api.addPostAdminMenuButton(attrs => {
          return {
            icon: "trash-can",
            label: "legal_compliance.delete_uploads_button",
            action: post => {
              const modal = container.lookup("service:modal");
              modal.show(_deleteUploadsModal.default, {
                model: {
                  post: post
                }
              });
            },
            secondaryAction: "closeAdminMenu",
            className: "delete-uploads"
          };
        });
      });
    }
  };
});