define("discourse/plugins/discourse-legal-compliance/discourse/connectors/reviewable-item-actions/legal-compliance-delete-uploads-button", ["exports", "@glimmer/component", "@ember/object", "discourse-i18n", "@ember/service", "discourse/components/d-button", "discourse/plugins/discourse-legal-compliance/discourse/components/modal/delete-uploads-modal", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _discourseI18n, _service, _dButton, _deleteUploadsModal, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LegalComplianceDeleteUploadsButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    get mustShow() {
      return this.args.outletArgs.reviewable.type == "ReviewableFlaggedPost" && this.args.outletArgs.reviewable?.raw?.includes("upload://");
    }
    openDeletionModal() {
      this.modal.show(_deleteUploadsModal.default, {
        model: {
          post: {
            id: this.args.outletArgs.reviewable?.post_id
          }
        }
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "openDeletionModal", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.mustShow}}
          <DButton
            @action={{action "openDeletionModal"}}
            class="btn-primary btn-warn"
            @label="legal_compliance.delete_uploads_button"
          />
        {{/if}}
      
    */
    {
      "id": "T/ChTb9C",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"mustShow\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-primary btn-warn\"]],[[\"@action\",\"@label\"],[[28,[31,1],[[30,0],\"openDeletionModal\"],null],\"legal_compliance.delete_uploads_button\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\",\"action\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-legal-compliance/discourse/connectors/reviewable-item-actions/legal-compliance-delete-uploads-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LegalComplianceDeleteUploadsButton;
});